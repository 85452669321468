import React from 'react';
import { NavLink } from 'react-router-dom';

import ImageArrow from 'assets/images/arrow.svg';

import { useI18n } from 'i18n/use-i18n';
import { NavigationList } from 'constants/navigationList';

const Navigation = ({ children, id }) => {
  const { translate } = useI18n();
  const closeMenu = () => {
    document.body.classList.remove('is-menu-open');
  };

  return (
    <nav className="nav">
      <ul>
        {NavigationList.map((item, index) => {
          const { label, link, title, submenus } = item;
          if (submenus) {
            return (
              <li key={index} className="nav__item expand-content">
                <input type="checkbox" id={`${id}-${index}`} />
                <label htmlFor={`${id}-${index}`} className="nav__title">
                  {translate(title)}
                  <img width={16} height={16} src={ImageArrow} alt="show" />
                </label>
                <ul className="expand-content__inner nav__submenu">
                  {submenus.map((submenu, subIndex) => {
                    const { label: subLabel, link: subLink } = submenu;
                    return (
                      <li key={subIndex}>
                        <NavLink onClick={closeMenu} to={subLink}>
                          {translate(subLabel)}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
          return (
            <li key={index} className="nav__item">
              <NavLink className="nav__title" onClick={closeMenu} to={link}>
                {translate(label)}
                <img width={16} height={16} src={ImageArrow} alt="show" />
              </NavLink>
            </li>
          );
        })}
        {children}
      </ul>
    </nav>
  );
};

export default Navigation;
