export const FAQListCommon = [
  {
    title: 'faq_item_1_title',
    text: 'faq_item_1_text'
  },
  {
    title: 'faq_item_2_title',
    text: 'faq_item_2_text'
  },
  {
    title: 'faq_item_3_title',
    text: 'faq_item_3_text'
  }
];

export const FAQListExtended = [
    ...FAQListCommon,
  {
    title: 'faq_item_4_title',
    text: 'faq_item_4_text'
  },
  {
    title: 'faq_item_5_title',
    text: 'faq_item_5_text'
  },
  {
    title: 'faq_item_6_title',
    text: 'faq_item_6_text'
  },
  {
    title: 'faq_item_7_title',
    text: 'faq_item_7_text'
  }
];
