import React from 'react';

import IconVk from 'assets/images/vk.svg';
import ImageBackground from 'assets/images/payment-vk-bg.jpg';
import ImageBackgroundWebp from 'assets/images/payment-vk-bg.webp';
import ImageBackgroundDesktopWebp from 'assets/images/payment-vk-bg-desktop.webp';
import ImageBackgroundDesktop from 'assets/images/payment-vk-bg-desktop.jpg';

import 'assets/styles/payment.scss';
import { useI18n } from "i18n/use-i18n";

function PaymentVk() {
  const { translate } = useI18n();
  return (
    <div className="payment">
      <div className="payment__heading">
        <picture className="payment__bg">
          <source type="image/webp" media="(max-width: 600px)" srcSet={ImageBackgroundWebp} />
          <source type="image/jpg" media="(max-width: 600px)" srcSet={ImageBackground} />
          <source
            type="image/webp"
            media="(min-width: 599px)"
            srcSet={ImageBackgroundDesktopWebp}
          />
          <source type="image/jpg" media="(min-width: 599px)" srcSet={ImageBackgroundDesktop} />
          <img src={ImageBackground} alt="" />
        </picture>
        <div className="container">
          <h1 className="title">{translate('paymentVk.title')}</h1>
          <p>
            {translate('paymentVk.text1')}
          </p>
          <p dangerouslySetInnerHTML={{ __html: translate('paymentVk.text2') }} />

          <a className="btn btn--lg btn--no-border" href="https://vk.com/vkchat">
            <img width={18} height={18} src={IconVk} alt="Vk" />
            Перейти в бота
          </a>
        </div>
      </div>

      <div className="payment__footer">
        <div className="container">
          <p dangerouslySetInnerHTML={{ __html: translate('paymentVk.footer') }} />
        </div>
      </div>
    </div>
  );
}

export default PaymentVk;
