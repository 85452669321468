import { fetchWithTimeout } from './fetch-with-timeout';

export const getUserCountry = async () => {
  try {
    const response = await fetchWithTimeout('https://geoapi.stivisto.com/analyze-ip?ip=mine');
    const data = await response.json();
    return data?.result?.country_iso_code ?? 'US';
  } catch (e) {
    // In case of any error, return 'US'
    console.log(e);
    return 'US';
  }
};

export const isUserFrom = async (userCountry) => {
  const country = await getUserCountry();
  return country.toUpperCase() === userCountry.toUpperCase();
};
