import { useEffect, useMemo } from 'react';
import createPersistedState from 'use-persisted-state';
const useColorSchemeState = createPersistedState('colorScheme');

export function useColorScheme() {
  const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  const [isDark, setIsDark] = useColorSchemeState();
  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [value]);

  return {
    isDark: value,
    setIsDark
  };
}
