import ru from './translations/ru.json';
import en from './translations/en.json';
import id from './translations/id.json';

const DEFAULT_LANGUAGE = 'en';

const translationsMap = {
  ru,
  en,
  id
};

export const translate = (language, stringKey, params = []) => {
  const translations = translationsMap[language] ?? translationsMap[DEFAULT_LANGUAGE];
  const translatedString =
    getNestedTranslation(translations, stringKey) ??
    getNestedTranslation(translationsMap[DEFAULT_LANGUAGE], stringKey);

  if (!translatedString) {
    return stringKey;
  }

  return insertParams(translatedString, params);
};

const getNestedTranslation = (translations, stringKey) => {
  return stringKey.split('.').reduce((obj, key) => {
    return obj && obj[key] !== undefined ? obj[key] : null;
  }, translations);
};

const insertParams = (translatedString, params = []) => {
  if (!params.length) {
    return translatedString;
  }

  let parsedString = translatedString;

  params.forEach((v, index) => {
    parsedString = parsedString.split(`{${index}}`).join(v);
  });

  return parsedString;
};
