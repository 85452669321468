import React from 'react';
import FAQItem from 'components/FAQItem';
import 'assets/styles/faq-page.scss';

import { FAQListExtended } from 'constants/FAQ';
import { useI18n } from 'i18n/use-i18n';

function FAQ() {
  const { translate } = useI18n();
  return (
    <div className="faq section">
      <div className="container">
        <h1 className="title title--xl">FAQ</h1>
        {FAQListExtended.map(({ title, text }, index) => (
          <FAQItem key={index} title={translate(title)} text={translate(text)} index={index} />
        ))}
      </div>
    </div>
  );
}

export default FAQ;
