import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useI18n } from "i18n/use-i18n";

import 'swiper/css';

import ImgSearch from 'assets/images/search.png';
import ImgAnon from 'assets/images/anon.png';
import ImgMedia from 'assets/images/media.png';
import ImgMail from 'assets/images/mail.png';

const aboutList = [
  {
    title: 'home_about_title1',
    text: 'home_about_text1',
    img: ImgSearch,
    alt: 'Master Card'
  },
  {
    title: 'home_about_title2',
    text: 'home_about_text2',
    img: ImgAnon,
    alt: 'Visa'
  },
  {
    title: 'home_about_title3',
    text: 'home_about_text3',
    img: ImgMedia,
    alt: 'Мир'
  },
  {
    title: 'home_about_title4',
    text: 'home_about_text4',
    img: ImgMail,
    alt: 'Vuesax'
  }
];

function HomeGallery() {
  const { translate } = useI18n();
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    }
  };
  return (
    <div className="home-about">
      <div className="container">
        <Swiper
          pagination={pagination}
          spaceBetween={10}
          slidesPerView={1}
          centeredSlides={true}
          modules={[Pagination]}
        >
          {aboutList.map(({ title, text, alt, img }, index) => (
            <SwiperSlide key={index}>
              <div className="card home-about__item">
                <picture className="home-about__pic">
                  <img src={img} alt={alt} />
                </picture>
                <div className="home-about__content">
                  <h3 className="title title--md">{translate(title)}</h3>

                  <p>{translate(text)}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default HomeGallery;
