import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, ScrollRestoration } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './assets/styles/global.scss';

import Layout from 'components/layout';
import LayoutPayment from 'components/layout/LayoutPayment';
import PaymentVk from 'pages/Payment';
import Home from 'pages/Home';
import Contacts from 'pages/Contacts';
import FAQ from 'pages/FAQ';
import BotTelegram from 'pages/BotTelegram';
import BotVk from 'pages/BotVk';
import SubscriptionsTelegram from 'pages/SubscriptionsTelegram';
import SubscriptionsVk from 'pages/SubscriptionsVk';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* Маршруты с использованием Layout */}
        <Route
          element={
            <Layout>
              <ScrollRestoration />
            </Layout>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="bot/telegram" element={<BotTelegram />} />
          <Route path="bot/vk" element={<BotVk />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="subscriptions/telegram" element={<SubscriptionsTelegram />} />
          <Route path="subscriptions/vk" element={<SubscriptionsVk />} />
        </Route>

        <Route element={<LayoutPayment />}>
          <Route path="payment" element={<PaymentVk />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
