const subscriptionVkDescription = [
  'subscriptions_vk_about1',
  'subscriptions_vk_about2',
  'subscriptions_vk_about3',
  'subscriptions_vk_about4',
  'subscriptions_vk_about5',
  'subscriptions_vk_about6'
];

const subscriptionTelegramDescription = [
  'subscriptions_telegram_about1',
  'subscriptions_telegram_about2',
  'subscriptions_telegram_about3',
  'subscriptions_telegram_about4'
];

export const subscriptionsListVk = [
  {
    price: '129₽',
    period: 'subscriptions_week',
    description: subscriptionVkDescription
  },
  {
    price: '179₽',
    period: 'subscriptions_month',
    description: subscriptionVkDescription
  },
  {
    price: '699₽',
    period: 'subscriptions_year',
    description: subscriptionVkDescription
  }
];

export const subscriptionsListTelegram = [
  {
    price: '149₽',
    priceUsd: '1_49$',
    period: 'subscriptions_week',
    description: subscriptionTelegramDescription
  },
  {
    price: '299₽',
    priceUsd: '2_99$',
    period: 'subscriptions_month',
    description: subscriptionTelegramDescription
  },
  {
    price: '999₽',
    priceUsd: '11_99$',
    period: 'subscriptions_year',
    description: subscriptionTelegramDescription
  },
  {
    price: '4999₽',
    priceUsd: '69$',
    period: 'subscriptions_vip',
    description: [...subscriptionTelegramDescription, 'subscriptions_telegram_about5', 'subscriptions_telegram_about6']
  }
];
