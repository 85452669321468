import React from 'react';
import { useI18n } from "../i18n/use-i18n";

import PromoBot from 'components/homePage/PromoBot';
import HomeGallery from 'components/homePage/HomeGallery';
import HomeFAQ from 'components/homePage/HomeFAQ';

import 'assets/styles/home-page.scss';
import 'swiper/css';

import TelegramIcon from 'assets/images/telegram.svg';
import ImageBot from 'assets/images/home-telegram.png';


function Home() {
  const { translate } = useI18n();
  return (
    <div className="home">
      <PromoBot />
      <div className="home-heading">
        <div className="container">
          <div className="card">
            <div className="home-heading__content">
              <h2 className="title title--lg">{translate('home_heading')}</h2>
              <a className="home-heading__link" href="https://t.me/anonrubot">
                @anonrubot
              </a>
              <a className="btn btn--lg" href="https://t.me/anonrubotx">
                <img width={18} height={18} src={TelegramIcon} alt="Telegram" />
                  {translate('botLink')}
              </a>
            </div>
            <img
              className="home-heading__img"
              width={217}
              height={337}
              src={ImageBot}
              alt="Telegram bot"
            />
          </div>
        </div>
      </div>
      <HomeGallery />
      <HomeFAQ />
    </div>
  );
}

export default Home;
