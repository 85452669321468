import React from 'react';
import LogoImage from 'assets/images/logo.svg';
import LogoImageDesktop from 'assets/images/logo-desktop.svg';
import 'assets/styles/logo.scss';

import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <>
      <NavLink className="logo" to="/">
        <img width={16} height={16} alt={'logo'} src={LogoImage} />
        anonchat.io
      </NavLink>
      <NavLink className="logo-desktop" to="/">
        <img width={36} height={36} alt={'logo'} src={LogoImageDesktop} />
        Анонимные Чаты
      </NavLink>
    </>
  );
};

export default Logo;
