import React from 'react';
import { useI18n } from "i18n/use-i18n";

import IconPlus from 'assets/images/plus.svg';


const SubscriptionItem = ({ children, price, priceUsd, period, description }) => {
  const { translate } = useI18n();
  return (
    <div className="subscriptions-item card">
      <div className="subscriptions-item__heading">
        <div className="subscriptions-item__price">
          <strong className="title">{price}</strong>
          {priceUsd && <p>{translate('subscriptions_or')} {priceUsd}</p>}
        </div>
        <div className="subscriptions-item__period">{translate(period)}</div>
      </div>
      <div className="subscriptions-item__content">
        <ul className="subscriptions-item__description">
          {description.map((item, index) => (
            <li key={index}>
              <img src={IconPlus} width={16} height={16} alt="" />
              {translate(item)}
            </li>
          ))}
        </ul>
        {children}
      </div>
    </div>
  );
};

export default SubscriptionItem;
