import React, { useEffect, useState } from 'react';
import Logo from '../Logo';
import Navigation from '../layout/Navigation';
import ThemeToggle from '../ThemeToggle';
import { toggleMenu } from 'util/toggle-menu';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <header id="page-header" className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header__wrap">
        <button onClick={toggleMenu} className="header__burger" type="button">
          <i>
            <span />
          </i>
        </button>
        <Logo />
        <Navigation />
        <ThemeToggle />
      </div>
    </header>
  );
};

export default Header;
