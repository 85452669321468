import React from 'react';

import 'assets/styles/subscriptions.scss';
import { subscriptionsListTelegram } from 'constants/subscriptionsList';
import SubscriptionItem from 'components/SubscriptionItem';
import { useI18n } from "i18n/use-i18n";

function SubscriptionsTelegram() {
  const { translate } = useI18n();
  return (
    <div className="subscriptions section">
      <div className="container">
        <h1 className="title title--xl">
          {translate('subscriptions_title')} <br />
          {translate('subscriptions_telegram_title')}
        </h1>
        <div className="subscriptions__list subscriptions__list--4">
          {subscriptionsListTelegram.map(({ price, priceUsd, period, description }, index) => (
            <SubscriptionItem
              key={index}
              price={price}
              priceUsd={priceUsd}
              period={period}
              description={description}
            >
              <a className="btn" href='https://t.me/anonrubot'>
                {translate('subscriptions_buy')}
              </a>
            </SubscriptionItem>
          ))}
        </div>
        <div className="subscriptions__description">
          <p>
            {translate('subscriptions_telegram_description')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsTelegram;
