import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import ImgAnon from 'assets/images/bots/anon.svg';
import ImgChat from 'assets/images/bots/chat.svg';
import ImgGender from 'assets/images/bots/gender.svg';
import ImgSend from 'assets/images/bots/send.svg';
import {useI18n} from "../i18n/use-i18n";

const aboutList = [
  {
    title: 'bots_gallery_title1',
    img: ImgAnon,
    alt: 'Anonymous'
  },
  {
    title: 'bots_gallery_title2',
    img: ImgChat,
    alt: 'Chat'
  },
  {
    title: 'bots_gallery_title3',
    img: ImgGender,
    alt: 'Gender'
  },
  {
    title: 'bots_gallery_title4',
    img: ImgSend,
    alt: 'Send'
  }
];

function BotsGallery() {
  const { translate } = useI18n();
  return (
    <div className="bots-gallery">
      <div className="container">
        <Swiper slidesPerView={'auto'} spaceBetween={10}>
          {aboutList.map(({ title, alt, img }, index) => (
            <SwiperSlide key={index}>
              <div className="bots-gallery__item card card--gradient-sm">
                <img className="bots-gallery__icon" src={img} alt={alt} />
                <h2 className="bots-gallery__title" dangerouslySetInnerHTML={{ __html: translate(title) }} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default BotsGallery;
