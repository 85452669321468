import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Menu from './Menu';

const LayoutPayment = () => {
  return (
    <div className="payment-layout">
      <Header />
      <main>
        <Outlet />
      </main>
      <Menu />
    </div>
  );
};

export default LayoutPayment;
