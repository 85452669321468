import { subscriptionsListVk } from 'constants/subscriptionsList';
import SubscriptionItem from 'components/SubscriptionItem';
import React from 'react';

import 'assets/styles/subscriptions.scss';
import { useI18n } from "../i18n/use-i18n";
import {NavLink} from "react-router-dom";

function SubscriptionsVk() {
  const { translate } = useI18n();
  return (
    <div className="subscriptions section">
      <div className="container">
        <h1 className="title title--xl">
          {translate('subscriptions_title')} <br />
          {translate('subscriptions_vk_title')}
        </h1>
        <div className="subscriptions__list subscriptions__list--3">
          {subscriptionsListVk.map(({ price, period, description }, index) => (
              <SubscriptionItem key={index} price={price} period={period} description={description} >
                <NavLink className="btn" to={'/payment'}>
                  {translate('subscriptions_buy')}
                </NavLink>
              </SubscriptionItem>
          ))}
        </div>
        <div className="subscriptions__description">
          <p>
            {translate('subscriptions_vk_description')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsVk;
